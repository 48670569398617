













import Vue from "vue";
import {Emit} from "vue-property-decorator";

const EditDialogProps = Vue.extend({
    props: {
        data: Object
    }
})


export default class EditDialog extends EditDialogProps {
    @Emit('update')
    update() {
        console.log('update');
    }

}
