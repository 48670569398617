








































































































import MyPbxToolsApi from "@/common/MyPbxToolsApi.ts";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import BoardData from "@/pb/data/BoardData";
import EditDialog from "@/components/EditDialog.vue";
import BoardPrototype from "@/pb/data/BoardPrototype";
import PowerboardForm from "@/components/pb/PowerboardForm.vue";
import {DataTableHeader} from "vuetify";
import Board from "@/pages/powerboards/Board.vue";
import BackendRest from "@/pb/BackendRest";
import Instance from "@/pb/data/Instance";
import App from "@/App.vue";

@Component({
    components: {PowerboardForm, EditDialog}
})
export default class BoardsTable extends Vue {
    boards: BoardData[] = [];
    instances: Instance[] = [];
    instancesById: Map<number, Instance> = new Map();
    backendApisById: Map<number, BackendRest> = new Map();
    loading: boolean = false;
    attemptDeleteTarget: BoardData | null = null;
    showCreate: boolean = false;
    prototype: BoardPrototype = {title: "Powerboard", template: false, backendId: 0};


    @Prop({type: Boolean, default: false})
    isTemplate!: boolean;
    readonly headers: DataTableHeader[] = [];

    get filterBackendId(): number | null {
        const raw: string | null = this.$route?.params.id;
        return raw ? parseInt(raw, 10) : null;
    }

    get includeInstanceColumn(): boolean {
        return this.isNormalBoard && this.filterBackendId == null;
    }

    @Watch('filterBackendId') onChangeBackendId() {
        this.reloadDatas();
    }

    process<T>(promise: Promise<T>) {
        promise.catch(err => console.error(err));
    }

    async updateBackendAsync(board: BoardData) {
        const id = board.backendId!;
        board.instance = this.instancesById.get(id);

        await this.reloadConfigAsync(board);
    }

    updateBackend(board: BoardData) {
        this.process(this.updateBackendAsync(board));
    }


    async reloadConfigAsync(board: BoardData) {
        const id = board.backendId!;
        const backendApi = this.backendApisById.get(id);
        if (backendApi == null)
            return;
        await backendApi.authAsync();
        await backendApi.forceConfigReloadAsync(board.token);
    }

    get isNormalBoard(): boolean {
        return !this.isTemplate;
    }

    get prototypeTitle(): string {
        return this.prototype?.title ?? "???";
    }

    set prototypeTitle(v: string) {
        this.prototype.title = v;
    }

    get attemptDelete(): boolean {
        return this.attemptDeleteTarget !== null;
    }

    set attemptDelete(attempt: boolean) {
        if (!attempt)
            this.attemptDeleteTarget = null;
    }

    getInstanceName(board: BoardData): string {
        return board?.instance?.name ?? "???";
    }

    linkTo(item: Board): string {
        return `/pb/${this.isTemplate ? 'template' : 'board'}/${item.token}`;
    }

    mounted() {
        this.reloadDatas();

        for (const header of this.buildHeaders()) {
            this.headers.push(header);
        }
    }

    reloadDatas() {
        this.process(this.reloadDatasAsync());
    }

    duplicateItem(wb: BoardData) {
        this.process(this.duplicateItemAsync(wb));
    }


    async duplicateItemAsync(wb: BoardData) {
        const newWb = await MyPbxToolsApi.instance.doWbAction<BoardData>(wb.id, 'copy');

        this.boards.push(Object.assign({instance: wb.instance}, newWb));
    }

    async deleteItem(wb: BoardData) {

        this.process(this.deleteItemAsync(wb));
    }

    async deleteItemAsync(wb: BoardData) {
        await MyPbxToolsApi.instance.doWbAction(wb.id, 'delete');
        this.boards = this.boards.filter(b => b.id !== wb.id);
    }

    updateItem(wb: BoardData) {
        this.process(this.updateItemAsync(wb));
    }

    async updateItemAsync(wb: BoardData) {

        await MyPbxToolsApi.instance.doWbAction(wb.id, 'update', {title: wb.title, backendId: wb.backendId});
        await this.reloadConfigAsync(wb);
    }

    createItem(wb: BoardPrototype) {
        this.process(this.createItemAsync(wb));
    }

    async createItemAsync(wb: BoardPrototype) {
        const item = await MyPbxToolsApi.instance.doWbActionGeneric<BoardData>('board', 'create', {
            backendId: wb.backendId,
            templateId: wb.templateId,
            template: wb.template,
            title: wb.title
        });

        this.boards.push(Object.assign({instance: this.instancesById.get(item!.backendId!)!}, item));
    }


    @Watch('isTemplate')
    onTemplateChanged() {
        this.reloadDatas();
    }

    async reloadDatasAsync() {
        this.loading = true;
        const data: any = {};
        if (this.isTemplate) {
            data.template = true;
        } else if (this.filterBackendId != null) {
            data.backendId = this.filterBackendId;
        }

        const boards: BoardData[] = await MyPbxToolsApi.instance.doWbActionGeneric('board', 'list', data);
        if (this.isTemplate) {
            this.boards = boards;
        } else {
            if (this.filterBackendId == null) {
                this.instances = await MyPbxToolsApi.instance.doWbActionGeneric<Instance[]>('instances', 'list');
            } else {
                const instance = await MyPbxToolsApi.instance.doWbActionGeneric<Instance>("instances", "get", {id: this.filterBackendId});
                this.instances = [instance];
            }
            for (const instance of this.instances) {
                this.instancesById.set(instance.id, instance);

                if (!this.backendApisById.has(instance.id)) {
                    this.backendApisById.set(instance.id, BackendRest.create(instance));
                }
            }
            this.boards = boards.map(b => ({instance: this.instancesById.get(b.backendId!)!, ...b}));
        }
        this.loading = false;
        this.prototype.backendId = this.instances[this.instances.length - 1].id;


        let appCandidate = this.$parent;
        while (!(appCandidate instanceof App) && appCandidate) {
            appCandidate = appCandidate.$parent;
        }
        const app = appCandidate as App;
        app.pageTitle = this.filterBackendId === null ? 'Powerboards' : `Powerboards for ${this.instances[0].name}`;
    }

    private* buildHeadersRaw(): Iterator<DataTableHeader> {
        yield {text: "ID", value: "id", align: 'end'};
        if (this.includeInstanceColumn) {
            yield {text: 'Instance', value: 'instance.name'};
        }
        yield {text: 'Title', value: 'title'};
        yield {text: '', value: 'actions', align: 'end'};
    }

    private buildHeaders(): Iterable<DataTableHeader> {
        const me = this;
        return {
            [Symbol.iterator](): Iterator<DataTableHeader> {
                return me.buildHeadersRaw();
            }
        }
    }
}
