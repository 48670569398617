var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"dark":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.reloadDatas}},[_vm._v(" Refresh   "),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.showCreate=true}}},[_vm._v(" Create  "),_c('v-icon',[_vm._v("mdi-table-plus")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.boards,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-form-textbox")])]}},{key:"header.instance.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-server")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")])]}},(_vm.isNormalBoard)?{key:"item.instance.name",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.instances,"item-value":"id","item-text":"name"},on:{"change":function($event){return _vm.updateBackend(item)}},model:{value:(item.backendId),callback:function ($$v) {_vm.$set(item, "backendId", $$v)},expression:"item.backendId"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.getInstanceName(item))+" ")])]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-content-duplicate","hint":"Duplicate","icon-color":"secondary"},on:{"click":function($event){return _vm.duplicateItem(item)}}}),(_vm.isNormalBoard)?_c('v-tooltip-btn',{attrs:{"href":("https://powerboard.mypbxtools.com/" + (item.token) + "/"),"btn-icon":"mdi-open-in-new","hint":"Open Powerboard","icon-color":"secondary"}}):_vm._e(),_c('v-tooltip-btn',{attrs:{"to":_vm.linkTo(item),"btn-icon":"mdi-cog","color":"secondary","hint":"Settings"}}),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-delete","hint":"Delete","icon-color":"error"},on:{"click":function($event){_vm.attemptDeleteTarget=item}}})],1)]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.attemptDelete),callback:function ($$v) {_vm.attemptDelete=$$v},expression:"attemptDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Are you sure you want to delete the Powerboard "+_vm._s(_vm.attemptDeleteTarget == null ? 'null' : _vm.attemptDeleteTarget.title)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.deleteItem(_vm.attemptDeleteTarget); _vm.attemptDelete = false;}}},[_vm._v(" Confirm "),_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){_vm.attemptDelete = false}}},[_vm._v(" Cancel "),_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.showCreate),callback:function ($$v) {_vm.showCreate=$$v},expression:"showCreate"}},[_c('powerboard-form',{attrs:{"instances":_vm.instances,"show":_vm.showCreate,"title":"Create Powerboard"},on:{"update:show":function($event){_vm.showCreate=$event},"submit":_vm.createItem},model:{value:(_vm.prototype),callback:function ($$v) {_vm.prototype=$$v},expression:"prototype"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }