































import Component from "vue-class-component";
import Vue from "vue";
import BoardPrototype from "@/pb/data/BoardPrototype";
import {Emit, ModelSync, Prop} from "vue-property-decorator";
import BoardData from "@/pb/data/BoardData";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import Instance from "@/pb/data/Instance";

@Component
export default class PowerboardForm extends Vue {
    @Prop({type: String})
    title!: string;

    @Prop({type: Array})
    instances!: Instance[];

    templates: BoardData[] = [];

    readonly noneTemplate: BoardData = {
        template: true,
        title: "None",
        id: -1,
        tiles: [],
        backendId: -1,
        bestCriteria: "",
        statsResetInterval: "",
        token: ""
    };

    @ModelSync('show', 'update:show', {type: Boolean})
    shouldShow!: boolean;

    @ModelSync('modelValue', 'update:modelValue', {type: Object})
    prototype!: BoardPrototype;

    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance;
    }

    @Emit()
    submit(prototype: BoardPrototype) {
        console.log('item submitted', prototype);
    }

    mounted() {
        this.reload();
    }

    async reloadAsync() {
        const rawTemplates = await MyPbxToolsApi.instance.doWbActionGeneric<BoardData[]>('board', 'list', {
            template: true,
            min: true
        });
        this.templates = [this.noneTemplate, ...rawTemplates];
        this.prototype.templateId = -1;
        this.prototype.backendId = this.instances[0].id;
    }

    reload() {
        this.reloadAsync().then(console.error);
    }
}
